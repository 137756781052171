import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetAllActivePurchasesByTenantIdResponse } from 'src/app/shared/data-access/models/server-requests-responses.model';
import { TenantInformationService } from 'src/app/shared/data-access/services/tenant-information/tenant-information.service';
import { TableColumnMetadata } from 'src/app/shared/ui/core/table/table-column-metadata';
import { TableColumnType } from 'src/app/shared/ui/core/table/table.component';

@Injectable({
	providedIn: 'root'
})
export class PurchaseActiveTableMetadataService {
	constructor(
		private translateService: TranslateService,
		private tenantInformationService: TenantInformationService
	) {}

	public getMetadata(): TableColumnMetadata<GetAllActivePurchasesByTenantIdResponse>[] {
		return [
			{
				header: this.translateService.instant('PAGE.PURCHASE.PURCHASE.NUMBER.LABEL'),
				linkAltText: 'PAGE.PURCHASE.TABLE.LINK.ALT',
				field: (purchase: GetAllActivePurchasesByTenantIdResponse) => purchase.purchaseNumber,
				sortColumn: 'purchaseNumber',
				renderType: TableColumnType.TEXT,
				isLink: true,
				isSortable: true,
				defaultSortField: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.SUPPLIER.LABEL'),
				field: (purchase: GetAllActivePurchasesByTenantIdResponse) => purchase.companyContact?.title,
				sortColumn: 'companyContact.title',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.CREATE_BY.LABEL'),
				field: (purchase: GetAllActivePurchasesByTenantIdResponse) => purchase.createdByAppUser?.employee?.initials,
				sortColumn: 'createdByAppUser.employee.initials',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.RESPONSIBLE_USER.LABEL'),
				field: (purchase: GetAllActivePurchasesByTenantIdResponse) => purchase.responsibleAppUser?.employee?.initials,
				sortColumn: 'responsibleAppUser.employee.initials',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('PAGE.PURCHASE.DATE.ORDERED.LABEL'),
				field: (purchase: GetAllActivePurchasesByTenantIdResponse) => purchase.dateOrdered,
				sortColumn: 'dateOrdered',
				renderType: TableColumnType.DATE,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.DATE_CREATED.LABEL'),
				field: (purchase: GetAllActivePurchasesByTenantIdResponse) => purchase.createdDate,
				sortColumn: 'createdDate',
				renderType: TableColumnType.DATE,
				isSortable: true,
				isVisible: true
			}
		];
	}
}
