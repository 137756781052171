import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
	GetAllActiveContactLogsByAppUserIdResponse,
	GetAllActiveContactLogsByTenantIdResponse
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { LocalStorageService } from 'src/app/shared/data-access/services/storage/local-storage/local-storage.service';
import { ToastManager } from 'src/app/shared/ui/services/toast/toast-manager.service';
import { RestInterceptor } from '../../../../shared/data-access/services/rest/rest.interceptor';
import { RestService } from '../../../../shared/data-access/services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class DashboardService extends RestService {
	constructor(
		httpClient: HttpClient,
		restInterceptor: RestInterceptor,
		localStorageService: LocalStorageService,
		router: Router,
		toastManager: ToastManager,
		translateService: TranslateService
	) {
		super(httpClient, restInterceptor, localStorageService, router, toastManager, translateService, '/dashboard');
	}

	public GetAllActiveContactLogsByTenantId(): Observable<GetAllActiveContactLogsByTenantIdResponse[]> {
		return super.get<GetAllActiveContactLogsByTenantIdResponse[]>(`/contact-logs/tenant-id`);
	}

	public GetAllActiveContactLogsByAppUserId(): Observable<GetAllActiveContactLogsByAppUserIdResponse[]> {
		return super.get<GetAllActiveContactLogsByAppUserIdResponse[]>(`/contact-logs/app-user-id`);
	}
}
